<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      right
      class="elevation-0"
      width="330"
      temporary
    >
      <reportar />
    </v-navigation-drawer>

    <v-toolbar
      height="30"
      dense
      flat
      dark
      color="orange"
    >
      <v-app-bar-nav-icon />
      <v-toolbar-title>Demo</v-toolbar-title>
    </v-toolbar>

        
    <v-card flat>
      <v-col
        v-for="oportunidad in oportunidades"
        :key="oportunidad.asunto"
        class="pa-1"
        cols="12"
      >
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            class="pa-0 ma-0"
            outlined
            elevation="10"
            :class="`elevation-${hover ? 12 : 2}`"
            @dblclick="editar(item)"
            @click.stop="agendarcita(oportunidad)"
          >
            <!-- <v-card-title>{{card.title}}</v-card-title>
              <v-card-subtitle>{{card.desc}}</v-card-subtitle> -->

            <v-list-item-subtitle class="mx-2 pt-1 blue--text titulo">
              {{ oportunidad.asunto }}
            </v-list-item-subtitle>
                
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="nombre black--text">
                  {{ oportunidad.nomcont }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="red--text">
                  {{ oportunidad.empresa }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-avatar
                  class="avatar"
                  color="red"
                  size="30"
                >
                  <img
                    class="avatar"
                    :src="oportunidad.avatarurl"
                    alt="John"
                  >
                </v-avatar>

                <h5 class="ma-1 justify-end">
                  {{ oportunidad.creada }}
                </h5>
                <!-- <v-btn small color="success">Editar</v-btn> -->
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'
import reportar from "@/views/crm/oportunidades/seguimientooport/demo/reportar"

export default {
  components:{
    reportar
  },
  data: () => ({
    drawer: false,
    group: null,
    oportunidades:[]
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  created () {
    this.consultar()
  },

  methods:{
    // ...mapActions('contactos',['setContacto','setLimpiarContacto']),
    ...mapActions('oportunidad',['setOportunidad']),
    ...mapActions("snackbar", ["showSnack"]),
    consultar(){
      // limpiar
      this.oportunidades= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      let urlbase ="https://soportesait.com/avatar/"
      
      //this.$http.get("v2/oportunidad.list" ).then(response=>{

      this.$http.get("v2/oportunidadxfase/3" ).then(response=>{
        if (response.data.error===null){
          this.oportunidades = response.data.result

          this.oportunidades.forEach( element => {
            //Ruta completa de la foto.
            element.avatarurl = urlbase + element.avatarurl
            // Formato de fecha corto.
            let cFecha =new Date( element.creada).toLocaleDateString(
              'es-mx',
              { timeZone: "UTC",
                month: 'short',
                day: 'numeric'
              }
            )
            element.creada = cFecha
            // element.fechastart = element.fechastart.substr(0,10)
            // element.hora = element.fechastart.substr(11,17)
          })
          console.log("oportunidades", this.oportunidades)
        }
          
      }).catch(error=>{
        console.log(error)
      })
    },

    
    agendarcita(opor1){
      console.log("oportunidad seleccionada",opor1)
      // Mandar llamar al Action.
      this.setOportunidad (opor1)

      this.drawer = !this.drawer
    }

  },
}

</script>


