<template>
  <v-container>
    <v-toolbar
      class="mb-1"
      color="transparent"
      dense
      flat
      height="40"
    >
      <v-toolbar-title><h4>SEGUIMIENTO DE OPORTUNIDADES</h4></v-toolbar-title>
      <v-btn
        small
        align="right"
        color="orange"
        dark
        class="ma-1"
        @click="regresar"
      >
        <v-icon>mdi-reply</v-icon>
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-row>
      <v-col
        cols="12"
        md="3"
        sm="3"
        xm="12"
        class="ma-0 pa-1"
      >
        <generada />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="3"
        xm="12"
        class="ma-0 pa-1"
      >
        <perfilada />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="3"
        xm="12"
        class="ma-0 pa-1"
      >
        <demo />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="3"
        xm="12"
        class="ma-0 pa-1"
      >
        <cierre />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import generada from '@/views/crm/oportunidades/seguimientooport/generada/generada.vue'
import perfilada from '@/views/crm/oportunidades/seguimientooport/perfilada/perfilada.vue'
import demo from '@/views/crm/oportunidades/seguimientooport/demo/demo.vue'
import cierre from '@/views/crm/oportunidades/seguimientooport/cierre/cierre.vue'
export default {
  components:{
    generada,
    perfilada,
    demo,
    cierre
  },
  methods:{
    regresar(){
      if (this.$route.name == 'seguimientooport' ){
        this.$router.push({name:'catoportunidades'})
      }
      
    },
  }
}
</script>

<style>

</style>